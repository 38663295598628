import React, { useState } from 'react';
import { Link } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Title } from '@components/atoms/Title';
import { Carousel } from '@components/molecules/Carousel/Carousel';
import { StreamingTeaser } from '@components/molecules/StreamingTeaser/StreamingTeaser';
import { TvCurrentProgram } from '@components/molecules/TvCurrentProgram/TvCurrentProgram';
import {
  PageContentElementTvmWatchlist as PageContentElementTvmWatchlistType,
  TvmFavoritesShowtimesDocument,
} from '@src/lib/graphql/generated';
import { usePaginatedAuthQuery } from '@src/lib/graphql/urql';
import { IconCarretRight } from '@assets/icon-carret-right';

export const PageContentElementTvmWatchlist = (
  element: PageContentElementTvmWatchlistType & { favoriteQuery: PageContentElementTvmWatchlistType['query'] },
) => {
  const [lastScroll, setLastScroll] = useState(0);
  const hasLink = element.title_link || element.title_link !== '';

  const type = element?.favoriteQuery?.type;

  const [{ data: showtimesData, fetching: showtimesLoading }] = usePaginatedAuthQuery({
    query: TvmFavoritesShowtimesDocument,
    variables: {
      orderBy: 'start',
      orderDirection: 'asc',
      first: 20,
      page: 1,
    },
    pause: type !== 'showtimes',
  });

  const showtimes = showtimesData?.flatMap((e) => e?.tvmFavoritesShowtimes?.data)?.filter(Boolean);
  if (
    (type === 'standard' && element?.favoriteQuery?.entities?.length < 1) ||
    (type === 'showtimes' && showtimes?.length < 1 && !showtimesLoading)
  ) {
    return null;
  }

  const entries = type === 'showtimes' ? showtimes : element?.favoriteQuery?.entities;

  return (
    <div className="relative pl-4 sm:pl-8 lg:pl-16">
      {element.title && (
        <Link
          noLink={!hasLink}
          href={element.title_link}
          classProps={{
            root: twMerge(
              'mb-3 flex select-none items-center font-herokid font-bold',
              hasLink && 'group cursor-pointer',
            ),
          }}
        >
          <Title
            level={2}
            classProps={{
              heading: 'relative text-xl lg:text-xl text-white leading-11 lg:leading-12',
            }}
          >
            {element.title}
          </Title>
          {hasLink && (
            <div className="relative ml-1 inline-grid h-full w-4 grid-cols-[1fr,auto] content-end items-center justify-end overflow-hidden whitespace-nowrap text-right text-2xs uppercase tracking-widest transition-all duration-300 group-hover:w-28 group-hover:text-primary">
              Alle sehen <IconCarretRight />
            </div>
          )}
        </Link>
      )}
      <section className="space-y-32">
        <Carousel
          onScrollComplete={() => {
            setLastScroll((prev) => prev + 1);
          }}
        >
          {type === 'standard'
            ? entries?.map((entry) => (
                <StreamingTeaser
                  entry={entry?.entity}
                  listicleType={entry?.entity?.__typename === 'Movie' ? 'MOVIE' : 'SHOW'}
                  lastScroll={lastScroll}
                  key={entry?.entity?.id}
                />
              ))
            : entries?.map((entry) => <TvCurrentProgram channelEntry={entry} lastScroll={lastScroll} key={entry.id} />)}
        </Carousel>
      </section>
    </div>
  );
};

export default PageContentElementTvmWatchlist;
