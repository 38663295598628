import { fetchListicleData } from './fetchListicleData';

export async function fetchExtraPageData(pageContent) {
  await Promise.all(
    pageContent.map(async (element) => {
      if (element.__typename === 'PageContentElementListicles') {
        try {
          const { data } =
            (await fetchListicleData(
              element.listicle_type,
              Number(element.limit),
              element.query,
              element.items,
              element.onlyWithAssets,
            )) ?? {};
          element.dataSet = data?.data?.data || data?.data || data || [];
        } catch (err) {
          console.error(err);
        }
      }
      return element;
    }),
  );
  return pageContent;
}
