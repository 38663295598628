import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { Title } from '@components/atoms/Title';

export const PageContentElementHeroImages = (Element) => {
  const { headline, text, images } = Element;

  return (
    <div className="grid content-center items-center gap-10 px-4 py-8 dark:text-white sm:px-8 lg:grid-cols-2 lg:px-16 xl:gap-20">
      <div className="max-w-screen-md">
        <Title
          classProps={{
            heading: 'lg:!text-7xl/none !text-5xl',
          }}
          level={1}
        >
          {headline}
        </Title>
        <div className="prose prose-streaming mt-10" dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      <div className="grid size-full gap-6 overflow-hidden lg:max-h-[80vh] lg:grid-cols-2 portrait-giant:max-h-[640px]">
        <div className="-ml-48 flex gap-6 lg:ml-0 lg:mt-[-50%] lg:grid lg:content-start lg:items-start">
          {images?.slice(0, 6).map((image, index) => (
            <div
              key={index}
              className="relative aspect-video w-64 overflow-hidden rounded-lg lg:aspect-square lg:w-full"
            >
              <Image
                src={image.url}
                alt={image.alt}
                fill
                classProps={{ root: 'object-cover' }}
                sizes="(min-width: 992px) 20vw, 500px"
                copyright={image.copyright_text}
              />
              ;
            </div>
          ))}
        </div>
        <div className="flex gap-6 lg:grid lg:content-start lg:items-start">
          {images?.slice(6, 11).map((image, index) => (
            <div
              key={index}
              className="relative aspect-video w-64 overflow-hidden rounded-lg lg:aspect-square lg:w-full"
            >
              <Image
                src={image.url}
                alt={image.alt}
                fill
                classProps={{ root: 'object-cover' }}
                sizes="(min-width: 992px) 20vw, 300px"
                copyright={image.copyright_text}
              />
              ;
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PageContentElementHeroImages;
