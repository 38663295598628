import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { FairuAsset } from '@src/lib/graphql/generated';

export interface AboutSheet {
  sheet_title: string;
  sheet_text: string;
  sheet_image: FairuAsset;
}
export interface AboutCardProps {
  cards: AboutSheet[];
  image?: FairuAsset;
}

export const AboutCard = ({ cards, image }: AboutCardProps) => {
  const offset = 80;
  return (
    <div className="relative border border-transparent">
      {image?.url && (
        <div className="relative mb-32 h-64 w-full">
          <Image
            src={image.url}
            alt={image.alt || ''}
            className="opacity-0 md:opacity-100"
            fill
            sizes="100vw"
            copyright={image.copyright_text || ''}
          />
          <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
            <span className="mb-4 text-label-base">Das ist TV-Media</span>
            <span className="mb-4 max-w-3xl font-herokid text-heading-mobile-1 md:text-heading-1">
              Suche, entdecke und finde Filme und Serien von...
            </span>
          </div>
        </div>
      )}
      {cards.map((card, index) => (
        <div
          key={index}
          className={'min-h-[360px] overflow-hidden rounded-2xl border-t border-gray-200 bg-white lg:sticky'}
          style={{ top: `${offset * (index + 1)}px`, marginBottom: `${offset * (cards.length - index)}px` }}
        >
          <div className="z-10 flex items-center gap-8 px-18 py-6 text-lg font-medium text-black">
            <span className="font-herokid">{'0' + (index + 1)}</span>
            <span className="text-xs font-bold uppercase tracking-2px">{card.sheet_title}</span>
          </div>
          <div className="flex flex-col justify-between bg-white px-6 pb-18 pt-0 lg:flex-row">
            <div className="basis-1/2 p-10">
              {card?.sheet_image?.url && (
                <div className="relative aspect-video size-full">
                  <Image
                    className="rounded-lg"
                    fill
                    src={card.sheet_image.url}
                    copyright={card?.sheet_title}
                    alt=""
                    sizes="(min-width: 992px) 50vw, 100vw"
                  />
                </div>
              )}
            </div>
            <div className="flex w-full items-center p-10 pt-0 lg:w-1/2">
              <a href="#" className="w-full focus:outline-none">
                {/* Extend touch target to entire panel */}
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="hyphenate font-herokid text-4xl font-bold leading-header text-black">
                  {card.sheet_title}
                </p>
                <div
                  className="mt-9 break-words text-lg text-black"
                  dangerouslySetInnerHTML={{ __html: card?.sheet_text }}
                />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
